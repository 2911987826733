import {
	ErrorBoundary,
	For,
	JSX,
	createResource,
	createSignal,
} from 'solid-js';
import './App.css';
// import { ApiClient } from './api_client';
import { ISODateString } from '../lib/date';
import { BudgetTransaction } from '../lib/transaction';
import { YNABClient } from './ynab_client';

// DELETE ALL THIS BEFORE CHECKING IT IN!
// const psk = 'INSECURE_TEST_PSK';
const ynabPSK = 'PLACEHOLDER';
const budgetId = 'PLACEHOLDER';

// Replace this with the 1st of the current month
const initialStartDate = ISODateString.deserialize('2023-06-01');

function TransactionCard({
	transaction,
}: {
	transaction: BudgetTransaction;
}): JSX.Element {
	return (
		<ErrorBoundary fallback={(err) => err}>
			<div>Transaction: {transaction.id}</div>
		</ErrorBoundary>
	);
}

function App(): JSX.Element {
	// const api = new ApiClient(psk);
	const ynab = new YNABClient(ynabPSK);
	const [sinceDate, _setSinceDate] = createSignal(initialStartDate);
	const [data] = createResource(sinceDate, (d) =>
		ynab.getTransactions({
			budgetId,
			sinceDate: d,
		}),
	);

	const transactions = () => data.latest?.transactions || [];

	return (
		<ErrorBoundary fallback={(err) => err}>
			<For each={transactions()}>
				{(t) => <TransactionCard transaction={t} />}
			</For>
		</ErrorBoundary>
	);
}

export default App;
