export function assertString(
	name: string,
	variable: any,
): asserts variable is string {
	const type = typeof variable;
	if (type === 'string') {
		return;
	}

	throw new Error(
		`Expected type string but received type ${type} for "${name}"`,
	);
}

export function assertNumber(
	name: string,
	variable: any,
): asserts variable is number {
	const type = typeof variable;
	if (type === 'number') {
		return;
	}

	throw new Error(
		`Expected type number but received type ${type} for "${name}"`,
	);
}

export function assertBoolean(
	name: string,
	variable: any,
): asserts variable is boolean {
	const type = typeof variable;
	if (type === 'boolean') {
		return;
	}

	throw new Error(
		`Expected type boolean but received type ${type} for "${name}"`,
	);
}

export function assertArray(
	name: string,
	variable: any,
): asserts variable is unknown[] {
	if (Array.isArray(variable)) {
		return;
	}

	throw new Error(
		`Expected type array but received non-array type ${typeof variable} for "${name}"`,
	);
}
