import { assertNumber } from './assert';
import { Xerializer } from './serialization';

/**
 * An amount of currency represented in "milliunits".
 * Most currencies don't have three decimal places, but you can think of it as
 * the number of thousandths of a unit in the currency: 1,000 milliunits equals
 * "one" unit of a currency (one Dollar, one Euro, one Pound, etc.). Here are
 * some concrete examples:
 *
 * Currency 	    Milliunits  	Amount
 * USD ($)          123930 	        $123.93
 * USD ($) 	        -220 	        -$0.22
 * Euro (€) 	    4924340     	€4.924,34
 * Euro (€) 	    -2990 	        -€2,99
 * Jordanian dinar 	-395032     	-395.032
 *
 * Matches the YNAB Amount format (https://api.ynab.com/#formats).
 */
export type Amount = number & { __amount: never };

export const Amount: Xerializer<Amount, number> = {
	serialize: (amount) => {
		return amount;
	},

	deserialize: (o) => {
		assertNumber('Amount', o);
		return o as Amount;
	},
};

export const ZERO = 0 as Amount;
export const negate = (amount: Amount): Amount => -amount as Amount;
export const asAmount = (value: number): Amount => Math.round(value) as Amount;
