import { Amount } from '../lib/amount';
import { ISODateString } from '../lib/date';
import { BudgetTransaction } from '../lib/transaction';
import { API, TransactionDetail, TransactionsResponse } from 'ynab';

export type GetTransactionsRequest = {
	budgetId: string;
	accountId?: string;
	sinceDate?: ISODateString;
	serverKnowledge?: number;
};

export type GetTransactionsResponse = {
	serverKnowledge: number;
	transactions: BudgetTransaction[];
};

export class YNABClient {
	private readonly api: API;

	constructor(token: string) {
		this.api = new API(token);
	}

	async getTransactions({
		budgetId,
		accountId,
		sinceDate,
		serverKnowledge,
	}: GetTransactionsRequest): Promise<GetTransactionsResponse> {
		const serializedSinceDate = sinceDate && ISODateString.serialize(sinceDate);

		let response: TransactionsResponse;
		if (accountId != null) {
			response = await this.api.transactions.getTransactionsByAccount(
				budgetId,
				accountId,
				serializedSinceDate,
				undefined,
				serverKnowledge,
			);
		} else {
			response = await this.api.transactions.getTransactions(
				budgetId,
				serializedSinceDate,
			);
		}

		return {
			serverKnowledge: response.data.server_knowledge,
			transactions: response.data.transactions.map((transaction) =>
				toBudgetTransaction(budgetId, transaction),
			),
		};
	}
}

function toBudgetTransaction(
	budgetId: string,
	t: TransactionDetail,
): BudgetTransaction {
	return {
		id: t.id,
		budgetId,
		accountId: t.account_id,
		accountName: t.account_name,
		date: ISODateString.deserialize(t.date),
		amount: Amount.deserialize(t.amount),
		memo: t.memo || undefined,
		payeeId: t.payee_id || undefined,
		payeeName: t.payee_name || undefined,
		categoryId: t.category_id || undefined,
		categoryName: t.category_name || undefined,
		importId: t.import_id || undefined,
		deleted: t.deleted,
	};
}

// function toSaveTransaction(
// 	t: Omit<BudgetTransaction, 'id'> & {
// 		cleared?: TransactionClearedStatus;
// 		approved?: boolean;
// 	},
// ): SaveTransaction {
// 	return {
// 		account_id: t.accountId,
// 		date: ISODateString.serialize(t.date),
// 		amount: Amount.serialize(t.amount),
// 		payee_id: t.payeeId,
// 		payee_name: t.payeeName,
// 		category_id: t.categoryId,
// 		memo: t.memo,
// 		cleared: t.cleared,
// 		approved: t.approved,
// 		import_id: t.importId,
// 	};
// }
