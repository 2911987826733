import { assertString } from './assert';
import { Xerializer } from './serialization';

/**
 * A date represented as an ISO 8601 (RFC 3339 "full-date") formatted string,
 * e.g. 2023-12-14. Always in UTC.
 *
 * Matches the YNAB Date format (https://api.ynab.com/#formats).
 */
export type ISODateString = string & { __isoDateString: never };

export const ISODateString: Xerializer<ISODateString, string> = {
	deserialize: (o) => {
		assertString('ISODateString', o);

		// Use the date constructor to test the date is valid;
		// really this accepts a variety of formats but it should be fine
		new Date(o);

		return o as ISODateString;
	},

	serialize: (isoDateString) => {
		return isoDateString;
	},
};
